import React, { useState } from "react";
import { ScrollView } from "react-native";
import { View, Text, Button, HStack, useToast } from 'native-base';
import { Card } from "react-native-elements";
import { IconButton } from "react-native-paper";
import { RFValue } from "react-native-responsive-fontsize";
import { useNavigation, useRoute } from "@react-navigation/native";

import { CommonDataTable } from "../../components/CommonDataTable";
import { LinkButton } from "../../components/LinkButton";
import { Loading } from "../../components/Loading";
import { OrderStatus } from "../../components/OrderStatus";
import { SubOrdersPerIndustry } from "../SubOrdersPerIndustry";
import { useRetailOrder } from "@hooks/useRetailOrder";
import { styles } from "./styles"
import { OrderStatusEnum } from "@enums/OrderStatusEnum";
import { useValidateCommercialPoliciesByCustomer } from "@hooks/useValidateCommercialPoliciesByCustomer";
import { IntegrationEnum } from "@enums/IntegrationEnum";
import { useAuth } from "@contexts/Auth";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { CartSurplusInput } from "@hooks/useCreateCartSurplus";
import { ToastAlert } from "@components/ToastAlert";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";

export const OrderDetail = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { data, loading } = useRetailOrder(
    route.params?.orderId as never
  );
  const toast = useToast();
  const { user } = useAuth();
  const industryCode = user.getNameIndustryStore();
  const [loadingSend, setLoadingSend] = useState(false);

  const [query] = useValidateCommercialPoliciesByCustomer(
  );

  const { createCartSurplusItens } = useShoppingCart()


  const getOrderStatus = (status: any) => {
    return <OrderStatus status={status} />;
  };

  function getCommercialPoliciesIds(products: any) {
    return [...new Set(products?.map((item: any) => item.commercialConditionId))];
  }


  function onErroValidation() {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={'Não é possível gerar um pedidos de itens faltantes pois há produtos em condições inválidas'}
            variant='subtle'
            status="error"
            isClosable
            _title={{
              color: 'warning.800'
            }}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
          />
        )
      }
    )
  }

  function onErrorFaliedCreateSupplusCart() {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={'Não foi possível gerar o carrinho'}
            variant='subtle'
            status="error"
            isClosable
            _title={{
              color: 'warning.800'
            }}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
          />
        )
      }
    )
  }

  const onGenerateOrderSurplus = async () => {
    const args = {
      industry: {
        code: industryCode,
      },
      prevailing: true,
      integrations: [IntegrationEnum.POINT_OF_SALES],
      availability: user.isIndustryEmployee() ? AvailabilityConsolidateEnum.INDUSTRY : AvailabilityConsolidateEnum.CUSTOMER
    };

    setLoadingSend(true)
    const { data: validateData } = await query({
      variables: {
        customer: { code: data?.Retail_retailOrder.customerCode },
        commercialPolicy: { ids: getCommercialPoliciesIds(industryProducts) }, ...args
      }
    })
      .catch(() => {
        onErroValidation()
        setLoadingSend(false)
      })

    if (!validateData?.validateCommercialPoliciesByCustomer?.valid) {
      onErroValidation()
      setLoadingSend(false)
      return;

    }

    const itensSurplus = industryProducts.filter(p => {
      if (p.invoiceQuantity) {
        return p.invoiceQuantity < p.quantity;
      }
      return p.responseQuantity < p.quantity;
    })

    const itensInput = itensSurplus.map(p => {
      return {
        code: p.ean,
        amount: p.invoiceQuantity ? p.quantity - p.invoiceQuantity : p.quantity - p.responseQuantity as number,
        commercialPolicyId: parseInt(p.commercialConditionId),
        discount: p.discountPercentage,
      }
    })


    const cart: CartSurplusInput = {
      customer: { code: data?.Retail_retailOrder.customerCode },
      ref: { externalId: data?.Retail_retailOrder.id, refId: data?.Retail_retailOrder.customerOrderCode },
      itens: itensInput,
      shippingOrder: data?.Retail_retailOrder?.wholesalerBranchesPayment?.map(wholesaler => {
        return {
          code: wholesaler.code,
          paymentTerm: wholesaler?.paymentTerm?.code ?? null
        }
      }) || []
    }
    const created = await createCartSurplusItens(cart)

    if (!created) {
      onErrorFaliedCreateSupplusCart();
      setLoadingSend(false)
      return;
    }
    navigation.navigate('SummaryOrder', { industry: industryCode });
  };

  const onParentOrder = async () => {
    navigation.navigate('OrderDetail', { orderId: data?.Retail_retailOrder?.parentOrder.id })
  };

  const onChildrenOrder = async () => {
    navigation.navigate('OrderDetail', { orderId: data?.Retail_retailOrder?.childrenOrder.id })
  };

  let industryOrders = [];
  let industryProducts: any[] = [];
  let orderId = "";

  const createRetailCard = (order: any) => {
    const retailProducts = order.products.map(function (product: any) {
      return {
        ean: [product.ean, 33],
        name: [product.name, 33],
        quantity: [product.quantity, 33],
      };
    });
    return (
      <Card key={String(Math.random())}>
        <View>
          {CommonDataTable(
            [["Id"], ["Data de Criação"], ["Status"]],
            [
              {
                id: [order.customerOrderCode, 33],
                data: [order.createdAt, 33],
                status: [getOrderStatus(order.status), 33]
              },
            ]
          )}
          {CommonDataTable([["EAN"], ["Nome"], ["Quantidade"]], retailProducts)}
        </View>
      </Card>
    );
  };

  if (data) {
    industryOrders = data.Retail_retailOrder.industryOrders;
    industryProducts = data.Retail_retailOrder.products;
    orderId = data.Retail_retailOrder.customerOrderCode;
  }

  return (
    <ScrollView style={styles.cardContainer}>
      <Card>
        <HStack justifyContent={'space-between'}>
          <Text style={styles.textTitleContentHeader}>
            <LinkButton
              style={[]}
              to={{
                screen: "ListOrder",
              }}
            >
              <IconButton
                icon={"arrow-left"}
                color="#74BF48"
                size={RFValue(9)}
                rippleColor="rgba(229, 229, 229, 0.5)"
              />
            </LinkButton>
            Resumo do Pedido #{orderId}
          </Text>

          <HStack justifyContent={'space-around'} space={3}>

            {(data?.Retail_retailOrder?.status == OrderStatusEnum.ORDER_PARTIALLY_ACCEPTED
              || data?.Retail_retailOrder?.status == OrderStatusEnum.ORDER_REJECTED)

              && !data?.Retail_retailOrder?.products.find(p => p.extraDiscounts.length > 0)
              && !data?.Retail_retailOrder?.childrenOrder?.id
              && (data?.Retail_retailOrder.industryOrders[0]?.industryWholesalerOrders?.length > 0)

              && <Button
                p={3}
                px={5}
                mb={3}
                rounded={'md'}
                onPress={onGenerateOrderSurplus}
                alignItems='center'
                justifyContent='center'
                _hover={{
                  opacity: 0.8,
                }}
                _pressed={{
                  bgColor: "muted.300",
                }}
                isLoading={loadingSend}

                spinnerPlacement="start"
                isLoadingText="Fazer pedido com itens não faturados"
                _text={{
                  fontWeight: '700',
                  fontSize: ['sm']
                }}
                _dark={{
                  opacity: 0.7,
                  _pressed: {
                    bgColor: "muted.700"
                  }
                }}
              >
                Fazer pedido com itens não faturados
              </Button>}
            {data?.Retail_retailOrder?.parentOrder
              && <Button
                testID='parent-order'
                p={3}
                px={5}
                mb={3}
                rounded={'md'}
                onPress={onParentOrder}
                alignItems='center'
                justifyContent='center'
                _hover={{
                  opacity: 0.8,
                }}
                _pressed={{
                  bgColor: "muted.300",
                }}
                _text={{
                  fontWeight: '700',
                  fontSize: ['sm']
                }}
                _dark={{
                  opacity: 0.7,
                  _pressed: {
                    bgColor: "muted.700"
                  }
                }}
              >
                Pedido Original
              </Button>}

            {data?.Retail_retailOrder?.childrenOrder
              && <Button
                p={3}
                px={5}
                mb={3}
                size={'md'}
                rounded={'md'}
                onPress={onChildrenOrder}
                alignItems='center'
                justifyContent='center'
                _hover={{
                  opacity: 0.8,
                }}
                _pressed={{
                  bgColor: "muted.300",
                }}
                _text={{
                  fontWeight: '700',
                  fontSize: ['sm']
                }}
                _dark={{
                  opacity: 0.7,
                  _pressed: {
                    bgColor: "muted.700"
                  }
                }}
              >
                Pedido Suplementar
              </Button>}
          </HStack>

        </HStack>
        {loading ? (
          <Loading />
        ) : industryOrders.length > 0 ? (
          industryOrders.map((ind: any) =>
            ind.industryWholesalerOrders.length > 0 ? (
              <SubOrdersPerIndustry
                key={String(Math.random()) + ind.industryCode}
                industry={ind.industryCode}
                subOrderRows={ind}
                products={industryProducts}
              />
            ) : (
              createRetailCard(data.Retail_retailOrder)
            )
          )
        ) : (
          createRetailCard(data.Retail_retailOrder)
        )}
      </Card>
    </ScrollView>
  );
};