import React, { useState } from "react";

import { ToastAlert } from "@components/ToastAlert";
import { useAuth } from "@contexts/Auth";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";
import { IntegrationEnum } from "@enums/IntegrationEnum";
import { CartSurplusInput } from "@hooks/useCreateCartSurplus";
import { useRetailOrder } from "@hooks/useRetailOrder";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useValidateCommercialPoliciesByCustomer } from "@hooks/useValidateCommercialPoliciesByCustomer";
import { useRoute, useNavigation } from "@react-navigation/native";
import { OrderType } from "@types/OrderType";
import { useToast } from "native-base";
import { OrderStatusEnum } from "@enums/OrderStatusEnum";

export const useOrderMobileModel = () => {

  const route = useRoute();
  const navigation = useNavigation();
  const { data, loading, error } = useRetailOrder(
    route.params?.orderId as never
  );
  const toast = useToast();
  const { user } = useAuth();
  const industryCode = user.getNameIndustryStore();
  const [loadingSend, setLoadingSend] = useState(false);

  const [query] = useValidateCommercialPoliciesByCustomer(
  );
  let industryProducts: any[] = [];
  let order: OrderType | null = null

  const { createCartSurplusItens } = useShoppingCart()

  function onErroValidation() {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={'Não é possivel gerar um pedidos de itens faltantes pois há produtos em condições inválidas'}
            variant='subtle'
            status="error"
            isClosable
            _title={{
              color: 'warning.800'
            }}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
          />
        )
      }
    )
  }

  function onErrorFaliedCreateSupplusCart() {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={'Não foi possivel gerar o carrinho'}
            variant='subtle'
            status="error"
            isClosable
            _title={{
              color: 'warning.800'
            }}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
          />
        )
      }
    )
  }

  function getCommercialPoliciesIds(products: any) {
    return [...new Set(products?.map((item: any) => item.commercialConditionId))];
  }


  const onGenerateOrderSurplus = async () => {
    const args = {
      industry: {
        code: industryCode,
      },
      prevailing: true,
      integrations: [IntegrationEnum.POINT_OF_SALES],
      availability: user.isIndustryEmployee() ? AvailabilityConsolidateEnum.INDUSTRY : AvailabilityConsolidateEnum.CUSTOMER
    };

    setLoadingSend(true)
    const { data: validateData } = await query({
      variables: {
        customer: { code: data?.Retail_retailOrder.customerCode },
        commercialPolicy: { ids: getCommercialPoliciesIds(industryProducts) }, ...args
      }
    })
      .catch(() => {
        onErroValidation()
        setLoadingSend(false)
      })

    if (!validateData?.validateCommercialPoliciesByCustomer?.valid) {
      onErroValidation()
      setLoadingSend(false)
      return;

    }

    const itensSurplus = industryProducts.filter(p => {
      if (p.invoiceQuantity) {
        return p.invoiceQuantity < p.quantity;
      }
      return p.responseQuantity < p.quantity;
    })

    const itensInput = itensSurplus.map(p => {
      return {
        code: p.ean,
        amount: p.invoiceQuantity ? p.quantity - p.invoiceQuantity : p.quantity - p.responseQuantity as number,
        commercialPolicyId: parseInt(p.commercialConditionId),
        discount: p.discountPercentage,
      }
    })


    const cart: CartSurplusInput = {
      customer: { code: data?.Retail_retailOrder.customerCode },
      ref: { externalId: data?.Retail_retailOrder.id, refId: data?.Retail_retailOrder.customerOrderCode },
      itens: itensInput,
      shippingOrder: data?.Retail_retailOrder?.wholesalerBranchesPayment?.map(wholesaler => {
        return {
          code: wholesaler.code,
          paymentTerm: wholesaler?.paymentTerm?.code ?? null
        }
      }) || []
    }
    const created = await createCartSurplusItens(cart)

    if (!created) {
      onErrorFaliedCreateSupplusCart();
      setLoadingSend(false)
      return;
    }
    navigation.navigate('SummaryOrder', { industry: industryCode });
    setLoadingSend(false)
  };


  if (data) {
    industryProducts = data.Retail_retailOrder.products;
    order = data?.Retail_retailOrder;
  }

  const shouldBuyItensSurply = (
    data?.Retail_retailOrder?.status == OrderStatusEnum.ORDER_PARTIALLY_ACCEPTED
    || data?.Retail_retailOrder?.status == OrderStatusEnum.ORDER_REJECTED
  )
    && !data?.Retail_retailOrder?.products.find(p => p.extraDiscounts.length > 0)
    && !data?.Retail_retailOrder?.childrenOrder?.id
    && (data?.Retail_retailOrder.industryOrders[0]?.industryWholesalerOrders?.length > 0)

  return { shouldBuyItensSurply, onGenerateOrderSurplus, loadingSend, loading, error, data, order }
}