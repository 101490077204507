import React from 'react';
import { Button, FlatList, View } from 'native-base';
import { UnexpectedError } from '@components/UnexpectedError';
import { OrderCard } from '@components/OrderCard';
import { Loading } from '@components/Loading';

import { OrderProductCard } from '@components/OrderProductCard';
import { useOrderMobileModel } from './OrderMobileModel';

export const OrderMobileView = ({
  shouldBuyItensSurply,
  onGenerateOrderSurplus,
  loadingSend,
  loading,
  error,
  order,
}: ReturnType<typeof useOrderMobileModel>) => {

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  if (!order) {
    return <UnexpectedError consoleError={'Pedido não encontrado!'} />;
  }

  return (
    <View p={6} flex={1}>
      <View mb={3} borderBottomWidth={1} borderBottomColor={'muted.300'}>
        <OrderCard
          status={order.status}
          id={order.customerOrderCode}
          customerCode={order.customerCode}
          createdAt={order.createdAt}
        />
      </View>
      <FlatList
        data={order.products}
        keyExtractor={(item) => item.ean}
        renderItem={({ item }) => <OrderProductCard product={item} />}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={loading ? <Loading /> : null}
      />

      <View mb={3} justifyContent={'flex-end'}>
        {shouldBuyItensSurply && <Button
          p={3}
          px={5}
          mb={3}
          rounded={'md'}
          onPress={onGenerateOrderSurplus}
          alignItems='center'
          justifyContent='center'
          _hover={{
            opacity: 0.8,
          }}
          _pressed={{
            bgColor: "muted.300",
          }}
          isLoading={loadingSend}
          spinnerPlacement="start"
          isLoadingText="Fazer pedido com itens não faturados"
          _text={{
            fontWeight: '700',
            fontSize: ['sm']
          }}
          _dark={{
            opacity: 0.7,
            _pressed: {
              bgColor: "muted.700"
            }
          }}
        >
          Fazer pedido com itens não faturados
        </Button>}
      </View>
    </View>
  );
}